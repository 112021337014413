import { default as confirmationXi4t3A5IZuMeta } from "/vercel/path0/pages/auth/confirmation.vue?macro=true";
import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as passwordUpdatenxM6KtrnUWMeta } from "/vercel/path0/pages/auth/passwordUpdate.vue?macro=true";
import { default as registerUZDCsj62s6Meta } from "/vercel/path0/pages/auth/register.vue?macro=true";
import { default as resetPasswordSj8nw4Bw0oMeta } from "/vercel/path0/pages/auth/resetPassword.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as orgDetailsDpR1d3tSFXMeta } from "/vercel/path0/pages/orgs/orgDetails.vue?macro=true";
import { default as orgList7jdYEnTfqiMeta } from "/vercel/path0/pages/orgs/orgList.vue?macro=true";
import { default as postCreatork4b5PKVWmAMeta } from "/vercel/path0/pages/posts/postCreator.vue?macro=true";
import { default as postDetailsciFn7dvv6bMeta } from "/vercel/path0/pages/posts/postDetails.vue?macro=true";
import { default as postEditorw8DSTPnNMKMeta } from "/vercel/path0/pages/posts/postEditor.vue?macro=true";
import { default as accountOOqX53acr2Meta } from "/vercel/path0/pages/users/account.vue?macro=true";
import { default as profilena6sllio3UMeta } from "/vercel/path0/pages/users/profile.vue?macro=true";
import { default as userDetailsqXINADmvw0Meta } from "/vercel/path0/pages/users/userDetails.vue?macro=true";
import { default as userListPp6JQNmIg2Meta } from "/vercel/path0/pages/users/userList.vue?macro=true";
export default [
  {
    name: "auth-confirmation",
    path: "/confirmation",
    meta: confirmationXi4t3A5IZuMeta || {},
    component: () => import("/vercel/path0/pages/auth/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/login",
    meta: loginILQdBdZzRsMeta || {},
    component: () => import("/vercel/path0/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-passwordUpdate",
    path: "/profile/password",
    meta: passwordUpdatenxM6KtrnUWMeta || {},
    component: () => import("/vercel/path0/pages/auth/passwordUpdate.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/register",
    meta: registerUZDCsj62s6Meta || {},
    component: () => import("/vercel/path0/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-resetPassword",
    path: "/reset-password",
    meta: resetPasswordSj8nw4Bw0oMeta || {},
    component: () => import("/vercel/path0/pages/auth/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/",
    meta: dashboardHdtnClvoGAMeta || {},
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "orgs-orgDetails",
    path: "/organizations/:slug",
    meta: orgDetailsDpR1d3tSFXMeta || {},
    component: () => import("/vercel/path0/pages/orgs/orgDetails.vue").then(m => m.default || m)
  },
  {
    name: "orgs-orgList",
    path: "/organizations",
    meta: orgList7jdYEnTfqiMeta || {},
    component: () => import("/vercel/path0/pages/orgs/orgList.vue").then(m => m.default || m)
  },
  {
    name: "posts-postCreator",
    path: "/posts/new",
    meta: postCreatork4b5PKVWmAMeta || {},
    component: () => import("/vercel/path0/pages/posts/postCreator.vue").then(m => m.default || m)
  },
  {
    name: "posts-postDetails",
    path: "/posts/:slug",
    meta: postDetailsciFn7dvv6bMeta || {},
    component: () => import("/vercel/path0/pages/posts/postDetails.vue").then(m => m.default || m)
  },
  {
    name: "posts-postEditor",
    path: "/posts/:slug/edit",
    meta: postEditorw8DSTPnNMKMeta || {},
    component: () => import("/vercel/path0/pages/posts/postEditor.vue").then(m => m.default || m)
  },
  {
    name: "users-account",
    path: "/account",
    meta: accountOOqX53acr2Meta || {},
    component: () => import("/vercel/path0/pages/users/account.vue").then(m => m.default || m)
  },
  {
    name: "users-profile",
    path: "/profile",
    meta: profilena6sllio3UMeta || {},
    component: () => import("/vercel/path0/pages/users/profile.vue").then(m => m.default || m)
  },
  {
    name: "users-userDetails",
    path: "/users/:slug",
    meta: userDetailsqXINADmvw0Meta || {},
    component: () => import("/vercel/path0/pages/users/userDetails.vue").then(m => m.default || m)
  },
  {
    name: "users-userList",
    path: "/users",
    meta: userListPp6JQNmIg2Meta || {},
    component: () => import("/vercel/path0/pages/users/userList.vue").then(m => m.default || m)
  }
]